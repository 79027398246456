import React from 'react'
import Slider from 'react-slick'

import './testimonials.css'

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: 'linear',
}

export const FeedbackSlider = () => (
  <Slider {...settings}>
    <div>
      <p>
        Maxpower Limited are successfully managing our Fixed Wire Testing and
        Emergency Lighting Testing programme for the next 5 years. The Maxpower
        engineers are professional throughout and considerate to minimise
        disruption, as testing was done during term time.
      </p>

      <p className="customer-name">
        <strong>Head of Facilities, Tower Hamlets Academy</strong>
        <br />
        London
      </p>
    </div>

    <div>
      <p>
        Maxpower Limited is a professional and efficient company that provides
        an excellent service. The staff have full knowledge of their services
        and are a credit to their company.
      </p>

      <p className="customer-name">
        <strong>Home Manager, Caring Homes</strong>
        <br />
        Reading
      </p>
    </div>

    <div>
      <p>
        “Our electrical operative attends site on a pre-arranged day that he
        books in with us. We know when he’s coming and he comes with all the
        correct materials and equipment. We are left to get on with our work.”
      </p>

      <p className="customer-name">
        <strong>Store manager of national convenience store</strong>
        <br />
        Cambridge
      </p>
    </div>
    <div>
      <p>
        “We wanted all our maintenance carried out by one provider to ensure an
        efficient service with a company that knew how we operated. We needed to
        trust them and ensure that not only were we safe and complaint, but
        getting good value. Maxpower Limited ticked all of those boxes.”
      </p>

      <p className="customer-name">
        <strong>Office Manager, Estate agency</strong>
        <br />
        Kent
      </p>
    </div>
    <div>
      <p>
        “We have one engineer turn up and carry out our compliance and safety
        works when it suits us. He attends, does all our testing and then
        explains how to access the certificates on the compliance portal.”
      </p>

      <p className="customer-name">
        <strong>Head of Centre of adventure centre</strong>
        <br />
        Norfolk
      </p>
    </div>
    <div>
      <p>
        “After Maxpower Limited attended the site to carry out tests, they
        recommended remedial action required. It was quoted and once the price
        was confirmed, installed on an agreed day as to not affect our trade.
        Excellent Support.”
      </p>

      <p className="customer-name">
        <strong>Pub Manager of national chain</strong>
        <br />
        Nationwide
      </p>
    </div>
  </Slider>
)

const Testimonials = () => (
  <div
    style={{
      marginTop: '1.45rem',
      marginBottom: '1.45rem',
      padding: '80px 0 50px 0',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="testimonial">
            <div className="row">
              <div className="col-md-4 testimonial-title">
                <h2>Customer Feedback</h2>
                <p>Our customers are at the heart or our organisation.</p>
              </div>
              <div className="col-md-8 slides">
                <FeedbackSlider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Testimonials
