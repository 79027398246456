import React from 'react'
import Link from 'gatsby-link'

import { FeedbackSlider } from '../Testimonials'

import './Sidebar.css'

const Sidebar = props => (
  <div className="sidebar">
    <p className="contact-sidebar">
      <span className="tagline">
        {props.title ? props.title : 'Electrical testing services'}:
      </span>
      <span className="phone">London: 020 3633 0956</span>
      <br />
      <span className="phone">Thetford: 01842 558212</span>
    </p>

    <h3>Services</h3>
    <ul className="service-list">
      <li>
        <Link
          to="/fixed-wire-testing/"
          title="EICR testing (Fixed wire testing) services"
        >
          EICR testing (Fixed wire testing)
        </Link>
      </li>
      <li>
        <Link
          to="/emergency-light-testing/"
          title="Emergency light testing services"
        >
          Emergency light testing
        </Link>
      </li>
      <li>
        <Link to="/pat-testing/" title="PAT testing services">
          PAT testing
        </Link>
      </li>
      <li>
        <Link to="/remedial-repairs/" title="Remedial repairs services">
          Remedial repairs
        </Link>
      </li>
    </ul>

    <h3>Specialist Sectors</h3>
    <ul className="service-list">
      <li>Education</li>
      <li>Retail</li>
      <li>Care homes</li>
      <li>Health &amp; Leisure</li>
      <li>Facilities Partners</li>
      <li>Social Housing</li>
      <li>Office &amp; Factories</li>
      <li>Warehousing</li>
      <li>Pubs &amp; Clubs</li>
      <li>Vets</li>
    </ul>

    <div className="sidebar-feedback">
      <FeedbackSlider />
    </div>
  </div>
)

export default Sidebar
