import React from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './footer.css'

const Footer = props => (
  <div
    style={{
      background: '#1a548e',
      color: '#fff',
      marginTop: '1.45rem',
      padding: '80px 0 25px 0',
    }}
  >
    <div className="footer container">
      <div className="row">
        {props.isHome ? (
          <div className="col-md-6 col-lg-3">
            <h3>Primary areas</h3>

            <ul>
              <li>
                <Link
                  to="/electrical-testing-services-in-norfolk/"
                  title="Maxpower Electrical testing services in Norfolk"
                >
                  Electrical testing services in Norfolk
                </Link>
              </li>
              <li>
                <Link
                  to="/electrical-testing-services-in-suffolk/"
                  title="Maxpower Electrical testing services in Suffolk"
                >
                  Electrical testing services in Suffolk
                </Link>
              </li>
              <li>
                <Link
                  to="/electrical-testing-services-in-essex/"
                  title="Maxpower Electrical testing services in Essex"
                >
                  Electrical testing services in Essex
                </Link>
              </li>
              <li>
                <Link
                  to="/electrical-testing-services-in-london/"
                  title="Maxpower Electrical testing services in London"
                >
                  Electrical testing services in London
                </Link>
              </li>
            </ul>

            <p className="social-icons">
              <a
                href="https://www.facebook.com/Maxpower-Limited-567584623396346/"
                title="Like Maxpower Electrical Compliance on Facebook"
                className="facebook"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-square']} />
              </a>
              <a
                href="https://www.linkedin.com/in/barrie-mcguire-01b10a119/"
                title="Follow Maxpower Electrical Compliance on Linkedin"
                className="linkedin"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
              <a
                href="https://twitter.com/maxpowerlimited"
                title="Follow Maxpower Electrical Compliance on Twitter"
                className="twitter"
              >
                <FontAwesomeIcon icon={['fab', 'twitter-square']} />
              </a>
            </p>
          </div>
        ) : (
          <div className="col-md-6 col-lg-3">
            <h3>Free quotations</h3>

            <p>
              Please get in contact and one of our helpful team can provide you
              with a no obligation, fee free quotation.
            </p>
            <p>
              <strong>Call us on</strong>
              <br /> London: 020 3633 0956
              <br /> Thetford: 01842 558212
              <br /> Email:{' '}
              <a
                className="email-link-footer"
                href="mailto:info@maxpowerlimited.uk"
                title="Email us"
              >
                info@maxpowerlimited.uk
              </a>
            </p>

            <p className="social-icons">
              <a
                href="https://www.facebook.com/Maxpower-Limited-567584623396346/"
                title="Like Maxpower Electrical Compliance on Facebook"
                className="facebook"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-square']} />
              </a>
              <a
                href="https://www.linkedin.com/in/barrie-mcguire-01b10a119/"
                title="Follow Maxpower Electrical Compliance on Linkedin"
                className="linkedin"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
              <a
                href="https://twitter.com/maxpowerlimited"
                title="Follow Maxpower Electrical Compliance on Twitter"
                className="twitter"
              >
                <FontAwesomeIcon icon={['fab', 'twitter-square']} />
              </a>
            </p>
          </div>
        )}

        <div className="col-md-6 col-lg-3">
          <h3>Sections</h3>

          <ul>
            <li>
              <Link
                to="/fixed-wire-testing/"
                title="EICR testing (Fixed wire testing) services"
              >
                EICR testing (Fixed wire testing)
              </Link>
            </li>
            <li>
              <Link
                to="/emergency-light-testing/"
                title="Emergency light testing services"
              >
                Emergency light testing
              </Link>
            </li>
            <li>
              <Link to="/pat-testing/" title="PAT testing services">
                PAT testing
              </Link>
            </li>
            <li>
              <Link to="/remedial-repairs/" title="Remedial repairs services">
                Remedial repairs
              </Link>
            </li>
            <li>
              <Link to="/contact/" title="Contact Maxpower Limited">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/policies/" title="Maxpower Limited Policies">
                Policies
              </Link>
            </li>
          </ul>
        </div>

        <div className="col-md-6 col-lg-3">
          <h3>London office</h3>
          <p>
            71-75 Shelton Street, <br />
            Covent Garden, <br />
            London <br />
            WC2H 9JQ
          </p>
          <p>
            <strong>Phone:</strong> 020 3633 0956
          </p>
        </div>
        <div className="col-md-6 col-lg-3">
          <h3>Thetford Office</h3>
          <p>
            Keystone Innovation Centre, <br />
            Croxton road, <br />
            Thetford, <br />
            Norfolk, <br />
            IP24 1JD
          </p>
          <p>
            <strong>Phone:</strong> 01842 558 212
          </p>
        </div>
      </div>

      <div className="row copyright" style={{ marginTop: '50px' }}>
        <div className="col-sm-6">
          <span>
            Copyright &copy; 2018{' '}
            <a
              href="https://www.maxpowerlimited.uk"
              title="Maxpower Limited - Electrical testing services"
            >
              Maxpower Limited
            </a>
            , Company Registration No.: 09593985
          </span>
        </div>

        <div className="col-sm-6 text-sm-right facebook-link">
          Find us on{' '}
          <a
            title="Like Maxpower Electrical Compliance on Facebook"
            href="https://www.facebook.com/Maxpower-Limited-567584623396346/"
          >
            Facebook
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
