import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Ratings from '../components/Ratings'

import './index.css'

library.add(fab, fas)

const meta = {
  title: 'Electrical Testing Services in Norfolk, Suffolk, Essex and London.',
  description:
    'Maxpower Limited is one of the UK’s leading independent Electrical Compliance safety testing specialists. Our engineers carry out Emergency light testing, EICR Fixed wire testing and PAT testing throughout Norfolk, Suffolk, Essex and the UK.',
  keywords:
    'Maxpower Limited,Nationwide Electrical Compliance,Maintenance,Emergency light testing,EICR testing,Fixed wire testing,PAT testing,Remedial repairs,electrical testing Norfolk,electrical testing Suffolk,electrical testing Essex',
  image: 'https://www.maxpowerlimited.uk/banner.jpg',
  url: 'https://www.maxpowerlimited.uk',
}

const TemplateWrapper = props => (
  <div>
    <Helmet
      title={meta.title}
      titleTemplate={
        props.location.pathname === '/'
          ? 'Maxpower Limited - %s'
          : '%s - Maxpower Limited'
      }
      meta={[
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords },
        { itemprop: 'name', content: meta.title },
        { itemprop: 'image', content: meta.image },
        { itemprop: 'description', content: meta.description },
        { property: 'og:title', content: meta.title },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: meta.description },
        { property: 'og:image', content: meta.image },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:description', content: meta.description },
        { name: 'twitter:image', content: meta.image },
        { name: 'twitter:url', content: meta.url },
        { name: 'msapplication-TileColor', content: '#ffffff' },
        { name: 'theme-color', content: '#ffffff' },
      ]}
      link={[
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png',
        },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#236ba6' },
        { rel: 'canonical', href: meta.url },
      ]}
    />

    <Header />

    <div>{props.children}</div>

    <Ratings />

    <Footer isHome={props.location.pathname === '/'} />
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
