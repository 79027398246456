import React from 'react'

import './feature.css'

const Feature = props => (
  <section className={'feature ' + props.image}>
    <div className="overlay">
      <div className="logo-overlay">
        <div className="container" style={{ maxWidth: '1000px' }}>
          {props.title ? (
            <h1 title={props.title}>{props.title}</h1>
          ) : (
            <h1 title="Electrical Testing Services">
              Electrical Testing Services
            </h1>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default Feature
