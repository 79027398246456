import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Sidebar from '../components/Sidebar'

const meta = {
  title: 'Emergency Light Testing Services',
  url: 'https://www.maxpowerlimited.com/emergency-light-testing/',
}

const EmergencyLightTestingPage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2>Emergency Lighting</h2>
            <p>
              Emergency lighting is lighting for an emergency situation when the
              main power supply is cut and any normal illumination fails. The
              loss of mains electricity could be the result of a fire or a power
              cut and the normal lighting supplies fail. This may lead to sudden
              darkness and a possible danger to the occupants, either through
              physical danger or panic.
            </p>

            <p>
              Emergency lighting is normally required to operate fully
              automatically and give illumination of a sufficiently high level
              to enable all occupants to evacuate the premises safely. Most new
              buildings now have emergency lighting installed during
              construction; the design and type of equipment being specified by
              the architect in accordance with current Building Regulations and
              any local authority requirements.
            </p>

            <p>
              The British Standard provides the emergency lighting designer with
              clear guidelines to work to. BS 5266-1: 2011 embraces residential
              hotels, clubs, hospitals, nursing homes, schools and colleges,
              licensed premises, offices, museums, shops, multi-storey
              dwellings, etc. Although this standard recommends the types and
              durations of emergency lighting systems relating to each category
              of premises, it should be remembered that the standards are the
              minimum safe standards for these types of building and that a
              higher standard may be required for a particular installation.
            </p>

            <h2 className="top-margin">What is emergency lighting?</h2>

            <p>
              Lighting that automatically comes on when the power supply to the
              normal lighting provision fails.
            </p>

            <p>
              Emergency lighting is a general term and is sub-divided into
              emergency escape lighting and standby lighting.
            </p>

            <p>
              Emergency escape lighting – that part of an emergency lighting
              system that provides illumination for the safety of people leaving
              a location or attempting to terminate a potentially dangerous
              process beforehand. It is part of the fire safety provision of a
              building and a requirement of The Regulatory Reform (Fire Safety)
              Order 2005.
            </p>

            <p>
              Standby lighting– that part of an emergency lighting system
              provided to enable normal activities to continue substantially
              unchanged. This guide does not include standby lighting as it is
              not a legal requirement and is a facility that may or may not be
              needed, depending on the use and occupancy of the premises, etc.
            </p>

            <p>
              Emergency escape lighting is itself sub-divided into escape route
              lighting, open area lighting and high risk task area lighting.
            </p>

            <p>
              Escape route lighting – that part of an emergency escape lighting
              system provided to ensure that the means of escape can be
              effectively identified and safely used by occupants of the
              building.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <Sidebar title={meta.title} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default EmergencyLightTestingPage
