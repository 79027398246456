import React from 'react'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './header.css'

const Header = () => (
  <header className="header container">
    <div className="row">
      <div className="col-8 col-md-3">
        <Link
          to="/"
          title="Maxpower Electrical Compliance"
          className="logo-link"
        >
          <img
            className="logo"
            src={require('./logo_2.png')}
            alt="Maxpower Electrical Compliance"
            title="Maxpower Electrical Compliance"
          />
          <span>Maxpower Electrical Compliance</span>
        </Link>
      </div>
      <div className="col-4 col-md-9 col-lg-9 text-right navigation">
        <span className="d-none d-lg-inline-block">
          <Link to="/fixed-wire-testing/" title="Fixed Wire Testing services">
            Fixed Wire Testing
          </Link>{' '}
          |
          <Link
            to="/emergency-light-testing/"
            title="Emergency Light Testing services"
          >
            Emergency Light Testing
          </Link>{' '}
          |
          <Link to="/pat-testing/" title="PAT Testing services">
            PAT Testing
          </Link>{' '}
          |
          <Link to="/remedial-repairs/" title="Remedial Repairs services">
            Remedial Repairs
          </Link>
          <Link
            className="btn btn-contact"
            to="/contact/"
            title="Contact Maxpower Limited"
          >
            Contact Us
          </Link>
        </span>

        <ul className="d-inline-block d-lg-none">
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle className="toggle" nav>
              <FontAwesomeIcon icon="bars" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link
                  to="/fixed-wire-testing/"
                  title="Fixed Wire Testing services"
                >
                  Fixed Wire Testing
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/emergency-light-testing/"
                  title="Emergency Light Testing services"
                >
                  Emergency Light Testing
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/pat-testing/" title="PAT Testing services">
                  PAT Testing
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/remedial-repairs/" title="Remedial Repairs services">
                  Remedial Repairs
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/contact/" title="Contact Maxpower Limited">
                  Contact Us
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
      </div>
      <div className="d-none">
        <div className="phone-container">
          <span className="tagline">Electrical compliance services:</span>
          <span className="phone">020 3633 0956</span>
        </div>
      </div>
    </div>
  </header>
)

export default Header
