import React from 'react'

import './ratings.css'

const Ratings = () => (
  <div className="ratings">
    <div className="wrapper">
      <a href="https://uk.trustpilot.com/review/maxpowerlimited.uk">
        <img
          src={require('./trustpilot.gif')}
          alt="Trustpilot Approved"
          title="Trustpilot Approved"
        />
      </a>
    </div>
    <div className="wrapper">
      <a
        href="https://www.bark.com/en/gb/company/maxpower-limited/v84nR/?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget?utm_medium=referral&utm_source=v84nR&utm_campaign=coe-widget&utm_content=coe-widget"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="bark-img"
          src={require('./bark-certificate.png')}
          alt="All major debit and credit cards accepted"
          title="All major debit and credit cards accepted"
        />
      </a>
    </div>
    <div className="wrapper card-pad">
      <img
        src={require('./cards-accepted.gif')}
        alt="All major debit and credit cards accepted"
        title="All major debit and credit cards accepted"
      />
    </div>
  </div>
)

export default Ratings
